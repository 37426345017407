import { useEffect, useState } from "react"
import { simplifyUrl, validateUrl } from "../../../utils"

export default function useDebounce(value: any, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      const isString = typeof value === "string"
      if (isString) {
        let newValue = value.trim()
        const isUrl = validateUrl(newValue)
        if (isUrl) {
          const urls = simplifyUrl(newValue)
          newValue = urls && urls.length > 0 ? urls[0]! : newValue
        }
        setDebouncedValue(newValue)
      } else {
        setDebouncedValue(value)
      }
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [delay, value])

  return debouncedValue
}
