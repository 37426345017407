import { useEffect, useRef, useState } from "react"
import { isMobile } from "react-device-detect"

const useHover = () => {
  const [value, setValue] = useState(false)
  const ref = useRef<HTMLDivElement | HTMLButtonElement>(null)
  const handleMouseOver = () => setValue(true)
  const handleMouseOut = () => setValue(false)

  useEffect(() => {
    const node = ref.current as any
    if (node) {
      node.addEventListener("mouseover", handleMouseOver)
      node.addEventListener("mouseout", handleMouseOut)
      return () => {
        node.removeEventListener("mouseover", handleMouseOver)
        node.removeEventListener("mouseout", handleMouseOut)
      }
    }
  }, [])
  return [ref, isMobile ? false : value]
}

export default useHover
