import React from "react"
import useHover from "src/core/hooks/useHover"
import { classNames } from "utils"
import Spinner from "./Spinner"

interface ToggleTextButtonProps {
  isToggled: boolean
  onText: string
  offText: string
  hoverAndOnText: string
  hoverAndOffText: string
  isLoading: boolean
  toggleOn: () => void
  toggleOff: () => void
}

const ToggleTextButton: React.FC<ToggleTextButtonProps> = ({
  isToggled,
  toggleOn,
  toggleOff,
  onText,
  offText,
  hoverAndOnText,
  hoverAndOffText,
  isLoading,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        e.nativeEvent.preventDefault()
        e.nativeEvent.stopImmediatePropagation()
        isToggled ? toggleOff() : toggleOn()
      }}
      className={classNames(
        isToggled
          ? " text-white bg-red-500 hover:bg-white hover:text-gray-400 hover:border-gray-300"
          : "hover:bg-red-500 hover:text-white bg-white text-gray-400 border-gray-300",
        "inline-flex items-center ml-2 px-2.5 py-1.5 border border-transparent hover:border-current text-md font-medium rounded shadow-sm  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      )}
      disabled={isLoading}
      ref={hoverRef as React.MutableRefObject<HTMLButtonElement>}
    >
      {isLoading ? (
        <Spinner />
      ) : isHovered ? (
        isToggled ? (
          hoverAndOnText
        ) : (
          hoverAndOffText
        )
      ) : isToggled ? (
        onText
      ) : (
        offText
      )}
    </button>
  )
}

export default ToggleTextButton
