import { getAntiCSRFToken } from "@blitzjs/auth"
import { IUserResult } from "src/users/users.interfaces"
import { IGetSubObjectivesInput } from "../pages/api/objectives/getSubObjectives"
import {
  ICreateObjectivePayload,
  IObjectiveResult,
  IOwnObjectivesResult,
} from "./objectives.interfaces"

const addObjective = async (payload: ICreateObjectivePayload) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response: Response = await fetch("/api/objective", {
    method: "POST",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  })
  try {
    return (await response.json()) as IObjectiveResult
  } catch (_e) {
    return null
  }
}

const searchObjectives = async (query: string) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/search?q=" + query, {
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return await response.json()
}

const getOwnObjectives = async (inactive?: boolean, fields?: string[]) => {
  const antiCSRFToken = getAntiCSRFToken()

  const params = new URLSearchParams()
  if (inactive) {
    params.append("active", "0")
  }
  if (fields) {
    params.append("fields", fields.join(","))
  }
  const response: Response = await fetch(`/api/objectives?${params.toString()}`, {
    method: "GET",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return (await response.json()) as IOwnObjectivesResult
}

const getUserObjectives = async (userId: number) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response: Response = await fetch("/api/objectives/users/" + userId, {
    method: "GET",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return await response.json()
}

const getObjective = async (id: number) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/objective/" + id, {
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return (await response.json()) as IObjectiveResult
}

const getUsers = async (id: number) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch(`/api/objective/${id}/users`, {
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
  })
  return (await response.json()) as IUserResult[]
}

const tag = async (objectiveId: number, parentObjectiveId: number, tagType: string) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/objectives/tag", {
    method: "POST",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify({
      objectiveId,
      parentObjectiveId,
      tagType,
    }),
  })
  return await response.json()
}

const updateObjectiveName = async (id: number, name: string): Promise<number> => {
  const antiCSRFToken = getAntiCSRFToken()
  const response: Response = await fetch(`/api/objective/${id}/name`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
    }),
  })
  return await response.status
}

const getSubObjectives = async (input: IGetSubObjectivesInput) => {
  const antiCSRFToken = getAntiCSRFToken()
  const response = await fetch("/api/objectives/getSubObjectives", {
    method: "POST",
    credentials: "include",
    headers: {
      "anti-csrf": antiCSRFToken,
    },
    body: JSON.stringify(input),
  })
  return await response.json()
}

const ObjectivesService = {
  getObjective,
  getOwnObjectives,
  addObjective,
  searchObjectives,
  getUsers,
  getUserObjectives,
  tag,
  updateObjectiveName,
  getSubObjectives,
}

export default ObjectivesService
