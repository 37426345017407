import { classNames } from "utils"

interface SpinnerProps {
  size?: number
  color?: string
  className?: string
}

const Spinner = ({ size = 6, color = "currentColor", className }: SpinnerProps) => {
  return (
    <div className={className}>
      <svg className={classNames(`w-${size}`, `h-${size}`, "animate-spin")} viewBox="0 0 24 24">
        <circle
          className="opacity-25"
          fill="none"
          cx="12"
          cy="12"
          r="10"
          stroke={color}
          strokeWidth="4"
        ></circle>
        <path
          className="opacity-75"
          fill={color}
          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
        ></path>
      </svg>
    </div>
  )
}

export default Spinner
