import React from "react"

interface Props {
  size?: number
}

const UsersListSkeleton = ({ size = 6 }: Props) => {
  return (
    <div className="flex flex-col mt-2 animate-pulse">
      {Array(size)
        .fill(true)
        .map((_value, idx) => (
          <div key={idx} className="flex-1 py-0.5">
            <div className="h-16 bg-gray-300"></div>
          </div>
        ))}
    </div>
  )
}

export default UsersListSkeleton
