import React, { ReactNode } from "react"
import useHover from "src/core/hooks/useHover"
import { classNames } from "utils"
import Spinner from "./Spinner"

interface ToggleIconButtonProps {
  isToggled: boolean
  onChildren: ReactNode
  offChildren?: ReactNode
  hoverAndOnChildren?: ReactNode
  hoverAndOffChildren?: ReactNode
  isLoading?: boolean
  toggleOn: () => void
  toggleOff?: () => void
  className?: string
  title?: string
}

const ToggleIconButton: React.FC<ToggleIconButtonProps> = ({
  isToggled,
  toggleOn,
  toggleOff,
  onChildren,
  offChildren,
  hoverAndOnChildren,
  hoverAndOffChildren,
  isLoading,
  className,
  title,
}) => {
  const [hoverRef, isHovered] = useHover()

  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
        isToggled && toggleOff ? toggleOff() : toggleOn()
      }}
      className={classNames(
        className,
        "flex items-center justify-center flex-shrink-0 w-12 text-sm font-medium"
      )}
      disabled={isLoading}
      ref={hoverRef as React.MutableRefObject<HTMLButtonElement>}
      aria-label={title}
      title={title}
    >
      {isLoading ? (
        <Spinner />
      ) : isHovered && hoverAndOnChildren ? (
        isToggled || !hoverAndOffChildren ? (
          hoverAndOnChildren
        ) : (
          hoverAndOffChildren
        )
      ) : isToggled || !offChildren ? (
        onChildren
      ) : (
        offChildren
      )}
    </button>
  )
}

export default ToggleIconButton
