/* This example requires Tailwind CSS v2.0+ */
import { Dialog, Transition } from "@headlessui/react"
import { XMarkIcon } from "@heroicons/react/24/outline"
import { Fragment, ReactNode, useEffect, useState } from "react"

interface SlideOverProps {
  title: string
  subtitle?: string
  children: ReactNode
  isOpen?: boolean
  onDismiss?: () => void
  icon?: ReactNode
}

const SlideOver = ({
  title,
  subtitle,
  children,
  onDismiss,
  isOpen = false,
  icon,
}: SlideOverProps) => {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={() => {
          setOpen(false)
          if (onDismiss) {
            onDismiss()
          }
        }}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0" />

          <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                <div className="flex flex-col h-full overflow-y-scroll bg-white shadow-xl">
                  <div className="px-4 py-6 bg-red-500 sm:px-6">
                    <div className="flex items-center justify-between">
                      <Dialog.Title className="text-lg font-medium text-white flex items-center flex-col">
                        {icon && <span className="mr-3">{icon}</span>}
                        <div className="flex flex-col">
                          {title}
                          {subtitle && <span className="text-sm text-gray-100">{subtitle}</span>}
                        </div>
                      </Dialog.Title>
                      <div className="flex items-center ml-3 h-7">
                        <button
                          className="text-red-200 bg-red-500 rounded-md hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => {
                            setOpen(false)
                            if (onDismiss) {
                              onDismiss()
                            }
                          }}
                        >
                          <span className="sr-only">Close panel</span>
                          <XMarkIcon className="w-6 h-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="relative flex-1 ">{children}</div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

export default SlideOver
