import { ExclamationCircleIcon } from "@heroicons/react/24/outline"
import React from "react"

interface ErrorRetryProps {
  message?: string
  retry: () => void
}

const ErrorRetry = ({ message, retry }: ErrorRetryProps) => {
  return (
    <div className="flex flex-col items-center justify-center text-center align-center">
      <ExclamationCircleIcon className="w-12 h-12 text-red-600" aria-hidden="true" />
      <div className="text-lg font-semibold text-red-600">Error</div>
      <p>{message || "Something went wrong. Please try again!"}</p>
      <button
        onClick={retry}
        className="inline-flex px-4 py-2 my-6 font-medium text-white bg-red-500 border border-transparent rounded-md shadow-sm text-md hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
      >
        Retry
      </button>
    </div>
  )
}

export default ErrorRetry
