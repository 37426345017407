import { Menu, Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"
import { classNames } from "utils"

export interface MenuOption {
  key: string
  value: string
  action: (key: string) => Promise<void>
}

interface MenuButtonProps {
  icon: ReactNode
  options: MenuOption[]
  name?: string
  className?: string
  title?: string
}

export default function MenuButton({ icon, options, name, className, title }: MenuButtonProps) {
  return (
    <Menu as="div" className="relative h-full flex justify-center items-center flex-col">
      {({ open }) => (
        <>
          <Menu.Button
            onClick={(e) => {
              e.stopPropagation()
              e.nativeEvent.stopImmediatePropagation()
            }}
            className={classNames(
              "flex items-center justify-center w-full h-full px-3 py-3 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-red-500 text-sm font-medium",
              className
            )}
            title={title}
          >
            {icon}
          </Menu.Button>
          <Transition
            show={open}
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <Menu.Items
              static
              className={classNames(
                "absolute right-0 w-24 mt-2 z-50 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
              )}
            >
              {!!name && (
                <div className="px-4 py-3">
                  <p className="text-sm font-medium text-center text-gray-900 truncate">{name}</p>
                </div>
              )}
              <div className="px-1 py-1 ">
                {options.map(({ key, value, action }) => (
                  <Menu.Item key={key}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          active ? "bg-red-400 text-white" : "text-gray-700",
                          "group flex rounded-md w-full px-2 py-2 text-sm  justify-center"
                        )}
                        onClick={async (e: any) => {
                          e.stopPropagation()
                          e.nativeEvent.stopImmediatePropagation()
                          await action(key)
                        }}
                      >
                        {value}
                      </button>
                    )}
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  )
}
