import { useMutation } from "@blitzjs/rpc"
import { UniqueIdentifier } from "@dnd-kit/core"
import constate from "constate"
import { useCallback, useEffect, useState } from "react"
import { useCurrentUser } from "src/core/hooks/useCurrentUser"
import { IObjectiveResult } from "src/objectives/objectives.interfaces"
import ObjectivesService from "src/objectives/objectives.service"
import createUserToObjective from "src/user-to-objectives/mutations/createUserToObjective"
import deleteUserToObjective from "src/user-to-objectives/mutations/deleteUserToObjective"
import updateUserToObjective from "src/user-to-objectives/mutations/updateUserToObjective"
import { IUserResult } from "src/users/users.interfaces"
import deleteUserToObjectives from "../user-to-objectives/mutations/deleteUserToObjectives"
import updateUserToObjectives from "../user-to-objectives/mutations/updateUserToObjectives"

interface IObjectiveProviderProps {
  id: number
  objective?: IObjectiveResult
}

const useObjective = ({ id, objective }: IObjectiveProviderProps) => {
  const user = useCurrentUser()

  const [currentObjective, setCurrentObjective] = useState<IObjectiveResult | undefined>(objective)
  const [objectiveLoading, setObjectiveLoading] = useState<boolean>(false)

  const [users, setUsers] = useState<IUserResult[]>([])
  const [usersLoading, setUsersLoading] = useState<boolean>(false)

  const [isAdopted, setIsAdopted] = useState<boolean>(!!objective?.adoptedAt)

  const getObjective = async (id: number) => {
    setObjectiveLoading(true)
    const objective = await ObjectivesService.getObjective(id)
    setCurrentObjective(objective)
    setObjectiveLoading(false)
  }

  const getObjectiveUsers = async (id: number) => {
    setUsersLoading(true)
    const users = await ObjectivesService.getUsers(id)
    setUsers(users)
    setUsersLoading(false)
  }

  useEffect(() => {
    setIsAdopted(!!currentObjective?.adoptedAt)
  }, [currentObjective?.adoptedAt])

  const [invokeAdopt, { isLoading: isAdopting }] = useMutation(createUserToObjective)
  const [invokeAbandon, { isLoading: isSingleAbandoning }] = useMutation(deleteUserToObjective)
  const [invokeBulkAbandon, { isLoading: isBulkAbandoning }] = useMutation(deleteUserToObjectives)
  const [isAbandoning, setIsAbandoning] = useState<boolean>(isSingleAbandoning || isBulkAbandoning)
  useEffect(() => {
    setIsAbandoning(isSingleAbandoning || isBulkAbandoning)
  }, [isSingleAbandoning, isBulkAbandoning])

  const adopt = useCallback(
    async (
      objectiveId: number,
      wasSuggested?: boolean,
      objectiveName?: string,
      parentId?: UniqueIdentifier,
      isActive?: boolean
    ) => {
      try {
        const userToObjective = await invokeAdopt({
          objectiveId: objectiveId < 0 ? undefined : objectiveId,
          wasSuggested,
          objectiveName,
          parentObjectiveId: parentId as number,
          isActive,
        })

        if (userToObjective) {
          const name = userToObjective.objectiveName || currentObjective?.name

          setCurrentObjective({
            id: userToObjective.objectiveId,
            name: name!,
            adoptedAt: userToObjective.createdAt,
            accessedAt: userToObjective.createdAt,
            parentId: null,
            depth: 0,
            index: 0,
          })

          if (user && user.profile) {
            setUsers([
              ...users,
              {
                id: user.id,
                username: user.profile.username,
                name: user.profile.name || "N/A",
              },
            ])
          }

          setIsAdopted(true)
          return userToObjective
        } else {
          return null
        }
      } catch (e) {
        console.error(e)
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentObjective?.name, invokeAdopt, user]
  )

  const abandon = useCallback(
    async (objectiveIds: number[]) => {
      try {
        if (!objectiveIds.length) return null

        if (objectiveIds.length > 1) {
          await invokeBulkAbandon({
            objectiveIds,
          })
          return true
        } else if (objectiveIds.length === 1) {
          const userToObjective = await invokeAbandon({
            objectiveId: objectiveIds[0]!,
          })

          if (userToObjective) {
            const name = userToObjective.objectiveName || currentObjective?.name

            setCurrentObjective({
              id: userToObjective.objectiveId,
              name: name!,
              adoptedAt: undefined,
              accessedAt: new Date(),
              userCount: currentObjective?.userCount ? currentObjective?.userCount - 1 : 1,
              parentId: null,
              depth: 0,
              index: 0,
            })
          }
          if (user && user.profile) {
            setUsers(users.filter((u) => u.id !== user.id))
          }

          setIsAdopted(false)

          return userToObjective
        } else {
          return null
        }
      } catch (e) {
        return null
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentObjective?.name, currentObjective?.userCount, invokeAbandon, user]
  )

  useEffect(() => {
    if (id && !objective) {
      getObjective(id).catch((_err) => {})
      getObjectiveUsers(id).catch((_err) => {})
    }
  }, [id, objective])

  const [userObjectiveUpdateMutation] = useMutation(updateUserToObjective)
  const [userObjectivesUpdateMutation] = useMutation(updateUserToObjectives)

  const updateObjectiveAccessedAt = async (id) => {
    if (user && isAdopted) {
      await userObjectiveUpdateMutation({
        objectiveId: id,
      })
    }
  }

  const updateObjectiveIsActive = async (ids: number[], isActive: boolean) => {
    if (user && isAdopted) {
      if (ids.length > 1) {
        await userObjectivesUpdateMutation({
          objectiveIds: ids.filter((id, index, self) => {
            return id >= 0 && self.indexOf(id) === index
          }),
          isActive,
        })
      } else if (ids[0] && ids[0] >= 0) {
        await userObjectiveUpdateMutation({
          objectiveId: ids[0]!,
          isActive,
        })
      }
    }
  }
  const updateObjectiveIsPrivate = async (ids: number[], isPrivate: boolean) => {
    if (user && isAdopted) {
      if (ids.length > 1) {
        await userObjectivesUpdateMutation({
          objectiveIds: ids,
          isPrivate,
        })
      } else {
        await userObjectiveUpdateMutation({
          objectiveId: ids[0]!,
          isPrivate,
        })
      }
    }
  }

  return {
    objectiveId: id,
    objective: currentObjective,
    setObjective: setCurrentObjective,
    objectiveLoading,

    users,
    setUsers,
    usersLoading,

    adopt,
    abandon,
    isAdopting,
    isAbandoning,
    isAdopted,
    setIsAdopted,

    updateObjectiveAccessedAt,
    updateObjectiveIsActive,
    updateObjectiveIsPrivate,
  }
}

export const [ObjectiveProvider, useObjectiveContext] = constate(useObjective)
