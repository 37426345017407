import React, { useRef } from "react"
import TimeAgo from "react-timeago"
import { checkIsToday, checkIsYesterday } from "utils"

interface ShortTimeAgoProps {
  date: Date
}

const ShortTimeAgo: React.FC<ShortTimeAgoProps> = ({ date }) => {
  const isToday = useRef(checkIsToday(new Date(date)))
  const isYesterday = useRef(checkIsYesterday(new Date(date)))

  if (isToday.current) {
    return <div>Today</div>
  } else if (isYesterday.current) {
    return <div>Yesterday</div>
  } else {
    return <TimeAgo date={date} live={false} />
  }
}

export default ShortTimeAgo
